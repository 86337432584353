import { faCircleInfo, faComment, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const CardNotification = ({ type, message, date, rawdate, navbar, uri, notifTime }) => {
    let badgeType = '';
    let iconType = '';
    let typeText = '';
    let target = '_self';
    let link = uri;

    switch (type) {
        case 'forum-reply':
            badgeType = 'danger';
            iconType = faComment;
            typeText = 'Jawaban Pertanyaan';
            break;
        case 'forum-comment':
            badgeType = 'danger';
            iconType = faComment;
            typeText = 'Komentar Pertanyaan';
            break;
        case 'reply':
            badgeType = 'dark';
            iconType = faComment;
            typeText = 'Komentar Diskusi';
            break;
        case 'comment':
            badgeType = 'dark';
            iconType = faComment;
            typeText = 'Balasan Diskusi';
            break;
        case 'information':
            badgeType = 'info';
            iconType = faCircleInfo;
            typeText = 'Informasi';
            break;
        case 'thread':
            badgeType = 'warning';
            iconType = faExclamationTriangle;
            typeText = 'Perhatian';
            break;
        default:
            break;
    }

    if (!uri?.startsWith('http://') && !uri?.startsWith('https://')) {
        link = `${process.env.REACT_APP_URL}/${uri}`;
    }

    if (uri?.startsWith('http://') || uri?.startsWith('https://')) {
        target = '_blank';
    }

    return (
        <Link
            className="link"
            to={link || '#'}
            onClick={(e) => {
                if (!link) e.preventDefault();
            }}
            style={{
                pointerEvents: !link ? 'none' : 'auto',
                cursor: !link ? 'not-allowed' : 'pointer',
            }}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
        >
            <div className={`card ${rawdate > notifTime ? 'border-3 border-primary' : 'border-0'} ${navbar && 'bg-light'}`}>
                <div className="card-body">
                    <div className="py-2">
                        <span className={`badge border border-${badgeType} text-${badgeType} py-2`}>
                            <FontAwesomeIcon icon={iconType} /> {typeText}
                        </span>
                        <span className="text-muted mx-2">|</span>
                        <small>
                            <span className="text-muted">{date}</span>
                        </small>
                    </div>
                    <div className="my-2">
                        <p className="text-muted" dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CardNotification;
