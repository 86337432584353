import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faCircleCheck, faClock, faShareFromSquare, faStarHalfStroke, faUsers } from '@fortawesome/free-solid-svg-icons';
import SectionRoadmapLastLearn from '../../../components/learn/Section/SectionRoadmapLastLearn/SectionRoadmapLastLearn';
import { useEffect, useState } from 'react';
import { getRoadmap } from '../../../features/roadmaps/roadmapSlice';
import Skeleton from 'react-loading-skeleton';
import ButtonShareSocmed from "../../../components/global/Buttons/ButtonShareSocmed/ButtonShareSocmed"
import SectionTimeline from '../../../components/learn/roadmap/SectionTimeline/SectionTimeline';

const MyRoadmapOverview = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()

    const [showShare, setShowShare] = useState(false)
    const { roadmap, loadingRoadmap } = useSelector(state => state.roadmap)
    const lastLearn = roadmap?.courses?.filter((item) => item.completed === false).at(0)
    const numberLastLearn = roadmap?.courses?.map((item, index) => item.id === lastLearn?.id && index + 1)

    useEffect(() => {
        dispatch(getRoadmap(slug))
    }, [dispatch, slug]);

    const groupByCategory = (items) =>
        items?.reduce((array, item) => {
            if (!array[item.category_title]) {
                array[item.category_title] = {
                    items: [],
                    description: item.category_description
                };
            }
            array[item.category_title].items.push(item);
            return array;
        }, {});

    const courses = groupByCategory(roadmap?.courses);

    return (
        <>
            <Helmet>
                <title>Roadmap Saya</title>
            </Helmet>
            <Layout>
                <section className="section mt-5 pb-4" id="learnHome">
                    <div className="container-fluid text-muted">
                        <div className="mb-3">
                            {/* Breadcrumb */}
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb fw-bold">
                                    <li className="breadcrumb-item"><Link to="/learn/courses" className="link text-primary">Kelas Saya</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{slug}</li>
                                </ol>
                            </nav>
                            {/* End breadcrumb */}

                            <div className="card px-3 px-lg-5 py-4 border-0 shadow-sm">
                                <span className="text-primary">By Originals <FontAwesomeIcon icon={faCircleCheck} /></span>
                                <h4 className="my-3 fw-bold">
                                    {
                                        loadingRoadmap
                                            ? (<Skeleton count={1} className="w-50" />)
                                            : (roadmap.path_name)
                                    }
                                </h4>
                                <p>
                                    {
                                        loadingRoadmap
                                            ? (
                                                <>
                                                    <Skeleton count={1} height={15} className="w-75" />
                                                    <Skeleton count={1} height={15} className="w-50" />
                                                </>
                                            )
                                            : <div dangerouslySetInnerHTML={{ __html: roadmap?.description }} />
                                    }
                                </p>
                                <div className="d-flex flex-column flex-lg-row mt-4 align-items-lg-center">
                                    <div className="mb-1 mb-lg-0">
                                        <FontAwesomeIcon icon={faStarHalfStroke} className="text-warning" />
                                        <small className="mx-2 text-warning">4.8</small>
                                        <small>(234 Penilaian)</small>
                                    </div>
                                    <div className="ms-lg-5 mb-1 mb-lg-0">
                                        <FontAwesomeIcon icon={faUsers} />
                                        <small className="mx-2">{roadmap?.total_students} Siswa</small>
                                    </div>
                                    <div className="ms-lg-4 mb-1 mb-lg-0">
                                        <FontAwesomeIcon icon={faClock} />
                                        <small className="mx-2">{roadmap?.duration}</small>
                                    </div>
                                    <div className="ms-lg-4 mb-3 mb-lg-0">
                                        <FontAwesomeIcon icon={faChartSimple} />
                                        <small className="mx-2">Level {_.capitalize(roadmap?.level)}</small>
                                    </div>
                                    <div className="ms-lg-auto">
                                        <button onClick={() => setShowShare(!showShare)} className="btn btn-primary">
                                            <FontAwesomeIcon icon={faShareFromSquare} className="me-1" />
                                            <span>Share</span>
                                        </button>
                                    </div>
                                </div>
                                {
                                    showShare && (
                                        <div className="row my-3">
                                            <div className="col text-center">
                                                <ButtonShareSocmed type="facebook" link={`https://codepolitan.com/roadmap/${roadmap.path_slug}`} />
                                                <ButtonShareSocmed type="twitter" link={`https://codepolitan.com/roadmap/${roadmap.path_slug}`} />
                                                <ButtonShareSocmed type="linkedin" link={`https://codepolitan.com/roadmap/${roadmap.path_slug}`} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <SectionRoadmapLastLearn
                            data={lastLearn}
                            numberLastLearn={numberLastLearn}
                        />

                        <h4 className="mt-5">Roadmap Materi</h4>
                        <SectionTimeline courses={courses} />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default MyRoadmapOverview;
