import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CardNotification from "../../components/global/Cards/CardNotification/CardNotification";
import Layout from "../../components/global/Layout/Layout";
import SkeleteonCardNotification from "../../components/skeletons/SkeletonCardNotification/SkeletonCardNotification";

const NotificationsPage = () => {
    const [limit, setLimit] = useState(10);
    const [notifTime, setNotifTime] = useState(0);

    const { notifications, loadingNotification } = useSelector((state) => state.notifications);

    useEffect(() => {
        const savedNotifTime = localStorage.getItem('notifTime');
        if (savedNotifTime) {
            setNotifTime(Number(savedNotifTime));
        } else {
            setNotifTime(0);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Notifikasi</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <h1 className="section-title h2 mb-3 h4">Notifikasi</h1>

                        <div className="row g-3">
                            {loadingNotification && [1, 2, 3].map(item => (
                                <div className="col-12" key={item}>
                                    <SkeleteonCardNotification />
                                </div>
                            ))}
                            {!loadingNotification && notifications.map((notification, index) => (
                                <div className="col-12" key={index}>
                                    <CardNotification
                                        type={notification.type}
                                        message={notification.meta.message}
                                        date={notification.date}
                                        uri={notification.meta.uri}
                                        rawdate={notification.rawdate}
                                        notifTime={notifTime}
                                    />
                                </div>
                            )).slice(0, limit)}
                        </div>
                        {notifications.length > limit && (
                            <div className="row my-3">
                                <div className="col text-center">
                                    <button onClick={() => setLimit(limit + 10)} className="btn btn-outline-secondary">Load more</button>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default NotificationsPage;
